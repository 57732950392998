import { FormattedMessage, injectIntl } from 'gatsby-plugin-intl';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import Masonry from 'react-masonry-css';
import GuestbookForm from '../components/guestbook-form';
import GuestbookMessage from '../components/guestbook-message';
import { InfiniteScroll } from '../components/infinite-scroll';
import Layout from '../components/layout';
import SEO from '../components/seo';
import getFirebase from '../utils/js/firebase';

const GuestbookPage = ({ intl }) => {
  const [messages, setMessages] = useState([]);
  const [lastMessage, setLastMessage] = useState();
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const isMounted = useRef(true);

  const breakpointColumnsObj = {
    default: 3,
    1024: 2,
    768: 1,
  };

  const firebase = getFirebase();

  const loadMore = () => {
    if (!firebase || loading || !hasMore) return;
    setLoading(true);

    let query;
    if (lastMessage) {
      query = firebase.firestore().collection('guestbook')
        .orderBy('createdAt', 'desc')
        .startAfter(lastMessage)
        .limit(10);
    } else {
      query = firebase.firestore().collection('guestbook')
        .orderBy('createdAt', 'desc')
        .limit(10);
    }

    query.get().then((querySnapshot) => {
      if (isMounted.current) {
        if (!querySnapshot.docs.length) setHasMore(false);
        const last = querySnapshot.docs[querySnapshot.docs.length - 1];
        setLastMessage(last);

        setMessages((oldMessages) => [...oldMessages, ...querySnapshot.docs.map(
          (doc) => (
            <GuestbookMessage
              data={doc.data()}
              key={doc.id}
            />
          ),
        )]);
        setLoading(false);
      }
    });
  };

  function handleMessageSubmitted() {
    setLastMessage(null);
    setMessages([]);
    loadMore();
  }

  useEffect(() => {
    loadMore();
    return () => {
      isMounted.current = false;
    };
  }, [firebase]);

  const loadingHtml = [
    <GuestbookMessage loading key="1" />,
    <GuestbookMessage loading key="2" />,
    <GuestbookMessage loading key="3" />,
    <GuestbookMessage loading key="4" />,
    <GuestbookMessage loading key="5" />,
    <GuestbookMessage loading key="6" />,
  ];

  const loadingTextHtml = (
    <p className="has-text-centered has-text-grey-lighter">
      <FormattedMessage id="guestbook.loading" />
    </p>
  );

  return (
    <Layout>
      <SEO title={intl.formatMessage({ id: 'guestbook.title' })} />
      <section className="container section">
        <h1 className="title is-4">
          <FormattedMessage id="guestbook.title" />
        </h1>
        <p>
          <FormattedMessage id="guestbook.intro" />
        </p>
      </section>
      <section className="hero is-light">
        <div className="hero-body ">
          <GuestbookForm onMessageSubmitted={handleMessageSubmitted} />
        </div>
      </section>
      <section className="container section">
        <InfiniteScroll
          threshold={250}
          hasMore={hasMore}
          onLoadMore={loadMore}
        >
          <Masonry
            breakpointCols={breakpointColumnsObj}
            className="my-masonry-grid"
            columnClassName="my-masonry-grid_column"
          >
            { loading ? [...messages, ...loadingHtml] : messages }
          </Masonry>
          { loading ? loadingTextHtml : ''}
        </InfiniteScroll>
      </section>
    </Layout>
  );
};

GuestbookPage.propTypes = {
  intl: PropTypes.object,
};

export default injectIntl(GuestbookPage);
