import { FormattedMessage } from 'gatsby-plugin-intl';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FacebookProvider, Page } from 'react-facebook';
import getFirebase from '../utils/js/firebase';
import { getFullLocale } from '../utils/js/locale-utils';
import { checkAnswer } from '../utils/js/security-question';
import GoogleAdsense from './google-adsense';

const GuestbookForm = ({ onMessageSubmitted }) => {
  const [values, setValues] = useState({
    name: '', email: '', message: '', security: '',
  });
  const [errors, setErrors] = useState({
    name: false, message: false, security: false,
  });
  const [loading, setLoading] = useState(false);
  const [messageSent, setMessageSent] = useState(false);

  const firebase = getFirebase();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
    setErrors({ ...errors, [name]: false });
    setMessageSent(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const err = {
      name: !values.name,
      message: !values.message,
      security: !checkAnswer(values.security),
    };

    setErrors((prevErrors) => ({ ...prevErrors, ...err }));

    if (!err.name && !err.message && !err.security) {
      firebase.firestore().collection('guestbook').add({
        name: values.name,
        message: values.message,
        email: values.email,
        createdAt: new Date(),
      }).then(() => {
        setLoading(false);
        setValues({
          name: '', email: '', message: '', security: '',
        });
        onMessageSubmitted();
        setMessageSent(true);
      });
    } else {
      setLoading(false);
    }
  };

  function errorInfo(showError, errorId) {
    return showError ? (
      <p className="help is-danger">
        <FormattedMessage id={errorId} />
      </p>
    ) : '';
  }

  return (
    <>
      <div className="container section">
        <div className="columns">
          <div className="column is-half">
            <form onSubmit={handleSubmit}>
              <div className="field">
                <label className="label">
                  <FormattedMessage id="guestbook.form.name" />
                </label>
                <div className="control has-icons-left">
                  <input className={`input ${errors.name ? 'is-danger' : ''}`} type="text" name="name" onChange={handleInputChange} value={values.name} />
                  <span className="icon is-small is-left">
                    <i className="fas fa-user" />
                  </span>
                </div>
                {errorInfo(errors.name, 'guestbook.form.errors.name')}
              </div>
              <div className="field">
                <label className="label">
                  <FormattedMessage id="guestbook.form.email" />
                </label>
                <div className="control has-icons-left">
                  <input className="input" type="email" name="email" onChange={handleInputChange} value={values.email} />
                  <span className="icon is-small is-left">
                    <i className="fas fa-envelope" />
                  </span>
                </div>
              </div>
              <div className="field">
                <label className="label">
                  <FormattedMessage id="guestbook.form.message" />
                </label>
                <div className="control">
                  <textarea className={`textarea ${errors.message ? 'is-danger' : ''}`} name="message" onChange={handleInputChange} value={values.message} />
                </div>
                {errorInfo(errors.message, 'guestbook.form.errors.message')}
              </div>
              <div className="field">
                <label className="label">
                  <FormattedMessage id="guestbook.form.month" />
                </label>
                <div className="control has-icons-left">
                  <input className={`input ${errors.security ? 'is-danger' : ''}`} type="text" name="security" onChange={handleInputChange} value={values.security} />
                  <span className="icon is-small is-left">
                    <i className="fas fa-robot" />
                  </span>
                </div>
                <p className="help">
                  <FormattedMessage id="guestbook.form.security" />
                </p>
                {errorInfo(errors.security, 'guestbook.form.errors.security')}
              </div>
              <div className="field is-grouped">
                <div className="control">
                  <div className="columns is-vcentered">
                    <div className="column">
                      <button type="submit" className={`button is-link ${loading ? 'is-loading' : ''}`}>
                        <FormattedMessage id="guestbook.form.send" />
                      </button>
                    </div>
                    <div className="column">
                      { messageSent ? (
                        <p className="help is-success is-size-6">
                          <FormattedMessage id="guestbook.form.success" />
                        </p>
                      ) : ''}
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="column is-half has-text-centered">
            <div className="m-t-lg has-text-centered" style={{ maxHeight: '320px' }}>
              <GoogleAdsense
                style={{ display: 'block' }}
                client="ca-pub-1569243764016253"
                slot="6913582508"
                format="auto"
                responsive="true"
              />
            </div>
            <div className="m-t-lg" style={{ maxHeight: '300px' }}>
              <FacebookProvider appId="4453936961347739" language={getFullLocale(process.env.GATSBY_LANG)}>
                <Page href="https://www.facebook.com/MesEtiquettes/" tabs="" showFacepile />
              </FacebookProvider>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

GuestbookForm.propTypes = {
  onMessageSubmitted: PropTypes.func,
};

GuestbookForm.defaultProps = {
};

export default GuestbookForm;
