import PropTypes from 'prop-types';
import React from 'react';
import { fromNow } from '../utils/js/date';
import guestbookMessageStyles from './guestbook.module.scss';

const GuestbookMessage = ({ data, loading }) => {
  const random3 = Math.floor(Math.random() * 3) + 1;
  const random4 = Math.floor(Math.random() * 4) + 1;
  let classes = guestbookMessageStyles.container;
  if (loading) classes += ` ${guestbookMessageStyles.loading}`;

  switch (random3 % 3) {
    case 1: classes += ` ${guestbookMessageStyles.isDanger}`;
      break;
    case 2: classes += ` ${guestbookMessageStyles.isWarning}`;
      break;
    default:
      classes += ` ${guestbookMessageStyles.isInfo}`;
  }

  switch (random4 % 4) {
    case 1: classes += ` ${guestbookMessageStyles.isLargeBorderRight}`;
      break;
    case 2: classes += ` ${guestbookMessageStyles.isLargeBorderTop}`;
      break;
    case 3: classes += ` ${guestbookMessageStyles.isLargeBorderBottom}`;
      break;
    default:
      classes += ` ${guestbookMessageStyles.isLargeBorderLeft}`;
  }

  return (
    <div className={classes}>
      <div className="p-md">
        <b>{data?.name || ' ' }</b>
        { data?.createdAt ? <i className="is-pulled-right">{fromNow(data?.createdAt?.toDate())}</i> : '' }
      </div>
      <div className="p-r-md p-l-md p-b-md">
        {data?.message}
      </div>
    </div>
  );
};

GuestbookMessage.propTypes = {
  data: PropTypes.object,
  loading: PropTypes.bool,
};

GuestbookMessage.defaultProps = {
};

export default GuestbookMessage;
